$(document).ready(function () {
    $(".partners-container").slick({
        infinite: true,
        speed: 1200,
        autoplaySpeed: 100,
        arrows: false,
        dots: true,
        dotsClass: "slider_dots",
        autoplay: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 530,
                settings: {
                    slidesToShow: 3,
                },
            },
        ],
    });
});
